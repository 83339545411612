import api from '..';
import config from '../../config';

export default {
  upload(file, options = {}) {
    const formData = new FormData();
    formData.append('file', file);

    return api.post('v1/files', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
      baseURL: config.api.url,
      ...options,
    });
  },
};
