<template>
  <div>
    <label v-if="label" :for="id">
      {{ label }}
      <span v-if="required" class="required">*</span>
    </label>

    <div class="input-group" v-if="value">
      <div class="form-control text-truncate">
        <a target="_blank" :href="url">{{ value.split('/').pop() }}</a>
      </div>
      <button
        class="btn btn-outline-danger"
        type="button"
        v-tooltip="'Hapus'"
        @click="$emit('input', null)"
      >
        <TrashIcon />
      </button>
    </div>

    <input
      class="form-control"
      type="file"
      v-bind="attrs"
      v-else
      :class="{ 'd-none': isUploading, 'is-invalid': error }"
      :id="id"
      @change="handleChange"
    />

    <span v-if="isUploading" class="progress">
      <div
        class="progress-bar progress-bar-striped progress-bar-animated"
        role="progressbar"
        aria-valuemin="0"
        aria-valuemax="100"
        :aria-valuenow="progress"
        :style="{ width: `${progress}%` }"
      ></div>
    </span>

    <div class="invalid-feedback">
      {{ error }}
    </div>

    <div class="form-text" v-if="desc" v-html="desc"></div>
  </div>
</template>

<script>
import fileRepository from '../../api/repositories/fileRepository';
import fileUtil from '../../utils/file';

import BaseField from './BaseField';

import TrashIcon from 'bootstrap-icons/icons/trash.svg';

export default {
  extends: BaseField,

  components: { TrashIcon },

  data() {
    return {
      isUploading: false,
      progress: null,
    };
  },

  computed: {
    url() {
      return this.value && fileUtil.getURL(this.value);
    },
  },

  methods: {
    async handleChange(event) {
      try {
        this.isUploading = true;
        const response = await fileRepository.upload(event.target.files[0], {
          onUploadProgress: (progressEvent) => {
            this.progress = progressEvent.lengthComputable
              ? (progressEvent.loaded / progressEvent.total) * 100
              : 100;
          },
        });

        this.$emit('input', response.data.data.path);
      } catch (error) {
        this.$alert.requestError(error);
      } finally {
        this.isUploading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.progress {
  height: rem(38);
}
</style>
