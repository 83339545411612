import pickBy from 'lodash/pickBy';
import config from '../config';
import fileUtil from './file';

export default {
  getThumbnailURL(path, width, height) {
    if (path.endsWith('.gif')) return fileUtil.getURL(path);

    const transform = btoa(
      JSON.stringify(pickBy({ w: width, h: height }, Boolean))
    ).replace(/==$/, '');

    return `${config.api.url}/v1/images/${transform}/${path}`;
  },
};
