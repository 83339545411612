<template>
  <div>
    <label v-if="label" :for="id">
      {{ label }}
      <span v-if="required" class="required">*</span>
    </label>

    <ckeditor
      v-bind="attrs"
      :class="{ 'is-invalid': error }"
      :value="value || ''"
      :editor="editor"
      :config="config"
      @input="$emit('input', $event)"
    />

    <div class="invalid-feedback">
      {{ error }}
    </div>
  </div>
</template>

<script>
import CKEditor from '@ckeditor/ckeditor5-vue2';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import UploadAdapterPlugin from '../../assets/js/ckeditor/plugins/upload-adapter';

import BaseField from './BaseField';

export default {
  extends: BaseField,

  components: { ckeditor: CKEditor.component },

  computed: {
    config() {
      return {
        toolbar: {
          items: [
            'heading',
            '|',
            'bold',
            'italic',
            'link',
            'bulletedList',
            'numberedList',
            '|',
            // 'outdent',
            // 'indent',
            // '|',
            'uploadImage',
            'blockQuote',
            'insertTable',
            'mediaEmbed',
            '|',
            'undo',
            'redo',
          ],
        },
        image: {
          toolbar: [
            // 'imageStyle:inline',
            // 'imageStyle:block',
            // 'imageStyle:side',
            // '|',
            'toggleImageCaption',
            'imageTextAlternative',
          ],
        },
        table: {
          contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells'],
        },
        mediaEmbed: {
          previewsInData: true,
        },
        extraPlugins: [UploadAdapterPlugin],
      };
    },
  },

  created() {
    this.editor = ClassicEditor;
  },
};
</script>

<style lang="scss">
:root {
  --ck-color-base-border: #{$input-border-color};
  --ck-border-radius: #{$border-radius};
  --ck-focus-ring: #{$border-width} solid #{$input-focus-border-color};
}

.ck {
  .is-invalid + & {
    .ck-toolbar,
    .ck-editor__editable_inline.ck-editor__editable_inline {
      border-color: $form-feedback-invalid-color;
    }
  }

  .ck-content {
    min-height: rem(240);
  }

  .ck-read-only.ck-read-only {
    background: $input-disabled-bg;
  }
}
</style>
